<template>
	<div class="content-wrap">
		<!-- 合同审查 -->
		<Spin fix v-if="loading"></Spin>
    <div class="search_box">
    <CtmsSearchBox class="search_left" @onSearch="searchHandle" :isShowReset="false" @onReset="reset">
      <CtmsSearchBoxItem :span="1" label="项目类型">
        <Select
          v-model="searchParams.projectTypeId"
          :transfer="true"
          placeholder="请选择类型"
        >
          <Option :value="item.id" v-for="item in projTypeList" :key="item.id">{{item.typeName}}</Option>
        </Select>
      </CtmsSearchBoxItem>
      <CtmsSearchBoxItem :span="1" label="创建日期">
        <DatePicker
          type="daterange"
          placeholder="请选择"
          :transfer="true"
          style="width: 100%"
          format="yyyy-MM-dd"
          :value="times"
          @on-change="v => (times = v)"
        ></DatePicker>
      </CtmsSearchBoxItem>
    </CtmsSearchBox>
    <div class="search_right">
      <p>合同审查<br>平均时间</p>
      <div class="echart-module-box">
        <EchartModule :val="avgVal"></EchartModule>
      </div>
    </div>
  </div>
		<CtmsAction>
      <Tab currentName="second"/>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="data"
      		:page="page"
			ref="table"
		>
		</CtmsDataGrid>
	</div>
</template>

<script>
import { mapState } from "vuex"
import Tab from "./components/Tab"
import EchartModule from "./components/EchartModule"

import api from "@/api/report/report"
const { apiGetContractReview, apiGetContractAvg } = api
import projectApi from "@/api/project/project"
const { apiGetProjTypeList } = projectApi
import './components/average-module.less'

export default {
	name: "WardCheckIn",
  components: {
    Tab,
    EchartModule
  },
	data() {
		return {
			loading: false,
      avgVal: -1,
      projTypeList: [],
			times: [],
			searchParams: {
        projectTypeId: "",
				createTimeStart: this.times ? this.times[0] : "",
				createTimeEnd: this.times ? this.times[1] : ""
			},
			oldSearchParams: {
        projectTypeId: "",
				createTimeStart: this.times ? this.times[0] : "",
				createTimeEnd: this.times ? this.times[1] : ""
			},
			data: [],
			columns: [
				{
					title: "项目名称",
					key: "projectName",
					minWidth: 600
				},
				{
					title: "机构办受理项目日期",
					key: "acceptDate",
					minWidth: 100,
				},
				{
					title: "合同签署日期间",
					key: "signDate",
					minWidth: 100
				},
				{
					title: "合同审查时长(天)",
					key: "reviewDays",
					minWidth: 50
				}
			],
			page: {
        		current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 140,
			storeSearchParams: state => state.report.searchParams,
			storeTimes: state => state.report.times
		})
	},
	watch: {
		storeSearchParams: {
			handler(newVal) {
				this.searchParams = { ...newVal }
				this.oldSearchParams = { ...newVal }
			},
			immediate: true
		},
		storeTimes: {
			handler(newVal) {
				this.times = [...newVal]
			},
			immediate: true
		}
	},
	mounted() {
    this.getProjType()
		// this.initData()
  },
  methods: {
    async apiGetAvg() {
      // 获取 平均值
      const data = await apiGetContractAvg({ ...this.oldSearchParams })
      this.avgVal = data || 0
    },
    async getProjType() {
      const res = await apiGetProjTypeList({ isDesigned: 1 })
      if (res) {
        const { data } = res
        this.projTypeList = data || []
        
        if (!this.storeSearchParams.projectTypeId) {
          const defaultId = this.projTypeList.length ? this.projTypeList[1].id : ""
          this.searchParams.projectTypeId = defaultId
          this.oldSearchParams.projectTypeId = defaultId
          
          this.$store.dispatch('report/updateSearchParams', { ...this.searchParams })
        } else {
          this.searchParams.projectTypeId = this.storeSearchParams.projectTypeId
          this.oldSearchParams.projectTypeId = this.storeSearchParams.projectTypeId
        }
      }
      this.initData()
    },
		initData() {
      this.apiGetAvg()
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetContractReview({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
				})
				if (res) {
          const { records, total } = res
          this.data = records || []
          this.page.total = total
				}
				this.loading = false
			})
		},
		// 重置
		reset() {
			this.times = []
			this.searchParams.projectTypeId = ""
			
			// 重置时也更新 store
			this.$store.dispatch('report/updateSearchParams', { ...this.searchParams })
			this.$store.dispatch('report/updateTimes', [])
			
			this.searchHandle()
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initData()
			})
		},
		searchHandle() {
			this.searchParams.createTimeStart = this.times.length ? this.times[0] : ""
			this.searchParams.createTimeEnd = this.times.length ? this.times[1] : ""
			this.oldSearchParams = Object.assign({}, this.searchParams)
			
			// 更新 store 中的查询条件
			this.$store.dispatch('report/updateSearchParams', { ...this.searchParams })
			this.$store.dispatch('report/updateTimes', [...this.times])
			
			this.page.current = 1
			this.$nextTick(() => {
				this.initData()
			})
		}
	}
}
</script>
